import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Image from "next/image";
import { IQuestPicture } from "../../src/interfaces";

interface IQuestCardPictureProps {
  picture: IQuestPicture;
  tokenGated: boolean;
  completed?: boolean;
  type?: "slider" | "banner" | "list";
  playVideo?: boolean;
}

function QuestCardPicture({
  picture,
  tokenGated,
  completed = false,
  type,
  playVideo = false,
}: IQuestCardPictureProps) {
  const [animationClasses, setAnimationClasses] = useState<string>("");
  const [pictureClasses, setPictureClasses] = useState<string>("");
  const [blisterClasses, setBlisterClasses] = useState<string>("");

  useEffect(() => {
    let tempPictureClasses = "";

    if (completed) {
      tempPictureClasses = `${tempPictureClasses} pt-[0%] pl-[15%] pr-[0%] pb-[19%] ml-[30px] rotate-[4.4deg] `;
    } else {
      tempPictureClasses = `${tempPictureClasses} pt-[10%] pl-[8%] pr-[7%] pb-[9%]`;
    }

    switch (type) {
      case "slider":
        setAnimationClasses("hover:scale-[1.01] transition duration-500");
        break;
      case "banner":
        setAnimationClasses("hover:rotate-[1deg] transition duration-500");
        break;
      case "list":
        if (completed) {
          setAnimationClasses("");
          tempPictureClasses = `${tempPictureClasses} group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition duration-500`;
          setBlisterClasses(
            "group-hover:rotate-[-8.7deg] transition duration-500"
          );
        } else {
          setAnimationClasses(
            "hover:rotate-[1deg] hover:scale-[1.01] transition duration-500"
          );
          setBlisterClasses("");
        }
        break;
      default:
        setAnimationClasses("");
        break;
    }

    setPictureClasses(tempPictureClasses);
  }, [type, completed]);

  return (
    <div className={twMerge("group relative w-full", animationClasses)}>
      {picture?.type === "image" ? (
        <div
          className={twMerge(
            `absolute w-full h-full ${completed ? "z-10" : ""}`,
            pictureClasses
          )}
        >
          <Image
            src={picture?.url || "/images/empty-quest-picture.png"}
            width={300}
            height={420}
            data-cy="WelcomeToWeb3"
            alt="quest"
          />
        </div>
      ) : (
        <video
          data-cy="WelcomeToWeb3"
          className={twMerge(
            `object-cover absolute w-full h-full ${completed ? "z-10" : ""}`,
            pictureClasses
          )}
          playsInline
          autoPlay={playVideo}
          muted
          loop
          poster=""
        >
          <source src={picture.url} type="video/mp4" />
        </video>
      )}
      {completed ? (
        <img
          src="/images/Blister.png"
          className={twMerge(
            "relative z-0 w-full h-full object-cover rotate-[-7.7deg] ml-[-30px]",
            blisterClasses
          )}
          alt="blister"
        />
      ) : (
        <img
          src="/images/Blister.png"
          className={twMerge(
            "relative z-0 w-full h-full object-cover",
            blisterClasses
          )}
          alt="blister"
        />
      )}
      {tokenGated &&
        (completed ? (
          <div className="absolute bottom-0 right-0 rotate-[-8.7deg] mr-[17px] mb-[3px]">
            <img src="/images/Token-gated.png" alt="token gated" />
          </div>
        ) : (
          <div className="absolute bottom-0 right-0">
            <img src="/images/Token-gated.png" alt="token gated" />
          </div>
        ))}
    </div>
  );
}

export default QuestCardPicture;
