import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { IPresentationQuest } from "../../src/interfaces";
import QuestCardPicture from "../QuestCardPicture";
import Status from "./Status";
import { questCardStatusThemeColor } from "../utils";
import TokenGatedInfo from "./TokenGatedInfo";
import Title from "./Title";

interface IQuestCardProps {
  now: Date;
  quest: IPresentationQuest;
  playVideo?: boolean;
  dark?: boolean;
  className?: string;
}

function QuestCard({
  now,
  quest,
  playVideo = false,
  dark = false,
  className = "",
}: IQuestCardProps) {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    setCompleted(!!quest.completed);
  }, [quest]);

  const handleClick = () => {
    const query = router.query.title
      ? {
          classroom: router.query.title,
        }
      : {};

    router.push({
      pathname: `/quests/${quest.slug}`,
      query,
    });
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={twMerge(
        "flex flex-col items-center text-left mx-auto md:mx-0",
        className
      )}
    >
      <div
        className={`flex flex-col ${
          dark ? "bg-gray-800" : "bg-gray-300"
        } w-72 phone:w-96 h-94 rounded-2xl overflow-hidden`}
      >
        <div className="flex items-center h-14 pl-3">
          <Status
            startDate={new Date(quest.startDate)}
            endDate={new Date(quest.endDate)}
            now={now}
          />
        </div>
        <div className="grow flex items-center justify-center">
          <div className={`${completed ? "w-32" : "w-48"}`}>
            <QuestCardPicture
              picture={quest.questPicture}
              tokenGated={quest.tokenGated}
              completed={completed}
              playVideo={playVideo}
              type="list"
            />
          </div>
        </div>
        <div className="relative h-11">
          {completed ? (
            <div className="flex items-center justify-center h-full bg-green">
              <span className="display-7 uppercase text-black">
                {t("status.completed")}!
              </span>
            </div>
          ) : (
            <div
              className={`absolute bottom-0 h-[6px] w-full ${questCardStatusThemeColor(
                quest.categoryId
              )}`}
            />
          )}
          {quest.tokenGated && !completed ? <TokenGatedInfo /> : null}
        </div>
      </div>
      <div className={`${dark ? "text-white" : "text-black"}`}>
        <Title title={quest.title} />

        <div className="flex gap-4 bodyText items-baseline w-72 phone:w-96 h-[22px] phone:h-7 mt-2 phone:mt-4">
          {quest.partnerName ? (
            <>
              <span className="captionText phone:bodyText">
                {t("featuring")}{" "}
              </span>
              <span className="display-7 phone:display-6 uppercase">
                {quest.partnerName}
              </span>
            </>
          ) : null}
        </div>
      </div>
    </button>
  );
}

export default QuestCard;
