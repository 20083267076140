import { useTranslation } from "next-i18next";

function TokenGatedInfo() {
  const { t } = useTranslation("common");

  return (
    <div className="absolute bottom-3 right-3 w-fit bg-purple-darker text-purple captionText p-3 rounded-md">
      {t("questIsTokenGated")}
    </div>
  );
}

export default TokenGatedInfo;
