import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { QuestStatusType } from "../../src/types";

interface StatusCompProps {
  status: QuestStatusType;
  daysLeft?: number;
  className?: string;
}

function StatusComp(props: StatusCompProps) {
  const { status, className = "", daysLeft = 0 } = props;
  const { t } = useTranslation("common");
  const [text, setText] = useState<string>("");
  const [textColor, setTextColor] = useState<"text-white" | "text-black">(
    "text-white"
  );
  const [icon, setIcon] = useState<
    "calendar_black" | "calendar_star_white" | "calendar_cross_white"
  >("calendar_black");
  const [bgColor, setBgColor] = useState<"bg-primary" | "bg-purple" | "bg-red">(
    "bg-purple"
  );

  useEffect(() => {
    switch (status) {
      case "AVAILABLE":
        setText(t("status.available"));
        setTextColor("text-black");
        setIcon("calendar_black");
        setBgColor("bg-purple");
        break;
      case "COMING_SOON":
        setText(t("status.comingSoon"));
        setTextColor("text-white");
        setIcon("calendar_star_white");
        setBgColor("bg-primary");
        break;
      case "DAYS_LEFT":
        setText(
          t("status.daysLeftWithCount", {
            count: daysLeft,
          }).toString()
        );
        setTextColor("text-black");
        setIcon("calendar_black");
        setBgColor("bg-purple");
        break;
      case "LAST_DAY":
        setText(t("status.lastDay"));
        setTextColor("text-black");
        setIcon("calendar_black");
        setBgColor("bg-purple");
        break;
      case "ENDED":
        setText(t("status.ended"));
        setTextColor("text-white");
        setIcon("calendar_cross_white");
        setBgColor("bg-red");
        break;
      default:
        setText("");
        setTextColor("text-white");
        setIcon("calendar_black");
        setBgColor("bg-purple");
        break;
    }
  }, [status]);

  return (
    <div
      className={twMerge(
        `w-fit flex gap-2 px-3 py-1 items-center rounded ${bgColor}`,
        className
      )}
    >
      <img src={`/icons/${icon}.svg`} alt="status icon" className="w-4 h-4" />
      <span
        className={`captionText ${textColor} ${
          status !== "DAYS_LEFT" ? "capitalize" : ""
        }`}
      >
        {text}
      </span>
    </div>
  );
}

export default StatusComp;
