import { QuestStatusType } from "../../src/types";

export const questThemeColor = (category: string) => {
  switch (category?.toLowerCase()) {
    case "community":
      return "text-purple";
    case "academy":
      return "text-primary";
    case "partner":
      return "text-gray-300";
    default:
      break;
  }
  return false;
};

export const questBgThemeColor = (category: string) => {
  switch (category?.toLowerCase()) {
    case "community":
      return "bg-purple";
    case "academy":
      return "bg-primary";
    case "partner":
      return "bg-gray-300";
    default:
      break;
  }
  return false;
};

export const questCardStatusThemeColor = (category: string) => {
  switch (category?.toLowerCase()) {
    case "community":
      return "bg-purple";
    case "academy":
      return "bg-primary";
    case "partner":
      return "bg-gray-400";
    default:
      break;
  }
  return false;
};

export const questTitle = (category: string) => {
  switch (category?.toLowerCase()) {
    case "community":
      return "COMMUNITY QUEST";
    case "academy":
      return "ACADEMY QUEST";
    case "partner":
      return "ACADEMY QUEST";
    default:
      break;
  }
  return false;
};

export const getStatusQuest = (
  startDate: Date,
  endDate: Date,
  now: Date
): QuestStatusType => {
  const startDateTime: number = startDate.getTime();
  const endDateTime: number = endDate.getTime();
  const nowTime: number = now.getTime();
  const hasEndDate: boolean = endDate.getTime() !== new Date(0).getTime();

  if (startDateTime > nowTime) {
    return "COMING_SOON";
  } else if (hasEndDate) {
    if (endDateTime < nowTime) {
      return "ENDED";
    }
    const oneDay = 60 * 60 * 24 * 1000;
    const tomorrow = nowTime + oneDay;
    if (endDateTime < tomorrow) {
      return "LAST_DAY";
    }
    return "DAYS_LEFT";
  }
  return "AVAILABLE";
};
