import { useEffect, useRef, useState } from "react";

interface TitleProps {
  title: string;
}

function Title({ title }: TitleProps) {
  const titleRef = useRef<HTMLDivElement>(null);
  const [parentFontSize, setParentFontSize] = useState<number>(16);
  const [size, setSize] = useState<number>(100);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries[0]) {
        const { clientHeight } = entries[0].target;
        setParentFontSize(clientHeight / 2);
      }
    });

    if (titleRef.current) {
      resizeObserver.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        resizeObserver.unobserve(titleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const maxHeight = titleRef.current.clientHeight;
    const currentHeight = titleRef.current.scrollHeight;

    if (currentHeight > maxHeight + 4 && size > 40) {
      setSize((size) => size - 1);
    }
  }, [parentFontSize, size]);

  return (
    <div style={{ fontSize: parentFontSize }}>
      <div
        ref={titleRef}
        className="w-72 phone:w-96 h-8 phone:h-14 display-7 phone:display-5 uppercase mt-4 phone:mt-6 line"
        style={{ fontSize: `${size}%`, lineHeight: `${size + 15}%` }}
      >
        {title}
      </div>
    </div>
  );
}

export default Title;
