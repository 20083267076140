import { useEffect, useState } from "react";
import { QuestStatusType } from "../../src/types";
import StatusComp from "./StatusComp";
import { getDifferenceInDays } from "../../helpers";
import { getStatusQuest } from "../utils";

interface StatusProps {
  startDate: Date;
  endDate: Date;
  now: Date;
}

function Status(props: StatusProps) {
  const { startDate, endDate, now } = props;
  const [questStatus, setQuestStatus] = useState<QuestStatusType | null>(null);

  const daysLeft: number = Number(getDifferenceInDays(now, endDate));

  useEffect(() => {
    setQuestStatus(getStatusQuest(startDate, endDate, now));
  }, [startDate, endDate, now]);

  return <StatusComp status={questStatus} daysLeft={daysLeft} />;
}

export default Status;
