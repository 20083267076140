import React from "react";
import { useTranslation, Trans } from "next-i18next";
import { SITE_DOMAIN } from "../../constants/global";

function SharePurpleArea() {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-row justify-center items-center px-4 w-full bg-purple">
      <div className="flex-1 hidden md:block align-center">
        <img
          className="mr-[300px] ml-auto"
          src="/images/Cosmo.png"
          width="280"
          height="266"
          alt="cosmo"
        />
      </div>
      <div className="flex-1 items-right py-20">
        <div className="text-heading-2 md:max-w-[395px]">
          {t("purpleArea.title")}
        </div>
        <div className="mt-5 leading-7">
          <Trans i18nKey="common:purpleArea.description" />
        </div>
        <div className="flex gap-4 mt-5">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${SITE_DOMAIN}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src="/icons/Homepage/facebook.svg" alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/ledger/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/icons/Homepage/instagram.svg" alt="instagram" />
          </a>
          <a
            href={`http://www.twitter.com/intent/tweet?url=${SITE_DOMAIN}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src="/icons/Homepage/twitter.svg" alt="twitter" />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${SITE_DOMAIN}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src="/icons/Homepage/linkedin.svg" alt="linkedin" />
          </a>
          <a
            href="https://discord.com/invite/ledger"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/icons/Homepage/discord.svg" alt="discord" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default SharePurpleArea;
